.expand-parent {
  background-color: #e7f8fe;
}
.expand-parent .ant-table-cell {
  background-color: #e7f8fe;
}
table > tbody > tr.expand-parent:hover {
  background-color: #e7f8fe;
}
.my-table .ant-table-expanded-row > .ant-table-cell {
  padding-left: 0 !important;
}

.my-table
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: -16px -16px -16px 0px !important;
}
