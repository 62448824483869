.main-header-container {
  align-items: center !important;
  height: inherit;
  justify-content: space-between;
}

.page-content {
  margin-top: 2em;
}

.main-header {
  padding: 0 !important;
  margin: 0 !important;
  align-items: center !important;
  height: inherit;
  justify-content: flex-end !important;
  margin-right: 2em !important;
}

.main-header > .qr-one-container {
  display: flex;
  align-items: center !important;
  margin-left: 20px;
}
.main-header > .logout {
  cursor: pointer;
}

.main-header > .qr-one-container > .qr-one {
  padding: 0 !important;
  margin: 0 !important;
}

.main-header > .qr-one-container > p {
  margin: 0 !important;
  padding: 0 !important;
  line-height: normal;
  margin-left: 10px !important;
}

.mb-1 {
  margin-bottom: 1rem;
}

.route-name {
  display: flex;
  text-transform: capitalize;
  margin-bottom: 4px;
  line-height: 24px;
  flex: 3 1;
  margin-left: 8px;
}

@media screen and (max-width: 576px) {
  .main-header {
    margin-right: 10px !important;
  }

  .main-header > .qr-one-container > p {
    font-size: 0.95em;
  }

  .main-header > .qr-one-container > .qr {
    display: none;
  }

  .main-header > .qr-one-container > .qr-text {
    display: none;
  }

  .ant-layout-header {
    position: fixed;
    z-index: 1000;
  }
  .jobs-page {
    margin-top: 70px;
  }
  .jobs-page .page-header-actions {
    margin: 5px 0 0 5px;
  }
  .extra-header.mob-tab .job-info {
    margin-top: 70px;
    flex-direction: column;
  }
  .extra-header.mob-tab .job-info .job-other-info {
    flex-direction: column;
  }
  .extra-header.mob-tab .job-info .job-other-info .job-info-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    border-top: 1px solid #dddddd;
    padding: 10px 0;
  }
  .extra-header.mob-tab .job-info .job-other-info .job-info-block span {
    margin-top: 0;
  }
  .job-profile-page .page-title {
    justify-content: start;
    padding: 10px 0 10px 15px;
  }
  .job-detail-drawer .ant-drawer-content-wrapper {
    width: 320px !important;
  }
  .job-detail-drawer .ant-drawer-content-wrapper .drawer-header {
    flex-direction: column;
    justify-content: space-between;
  }
  .job-detail-drawer .ant-drawer-content-wrapper .drawer-header .header-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .job-create-drawer .ant-drawer-content-wrapper {
    width: 320px !important;
  }
  .job-detail-drawer
    .ant-drawer-content-wrapper
    .drawer-header
    .header-actions {
    width: 100%;
  }
  .main-header-container {
    padding: 0 1em;
  }
}
