.clients-page .page-header {
  display: block;
  padding-bottom: 1rem;
}
.clients-page .page-header .ant-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

body #root .ant-tabs-tab {
  font-size: 16px;
  margin: 0;
  padding: 15px;
}
body #root .ant-tabs-tab-active {
  color: #2d9cdb;
  font-weight: 500;
  border-bottom: 3px solid #2d9cdb;
}
