* {
  font-family: Roboto;
}
:root {
  --primary: #2f80ed;
}
body {
  height: auto;
}

.ant-layout .ant-layout-header {
  background-color: white;
  box-shadow: 2px 2px 9px rgba(178, 178, 178, 0.2);
  z-index: 9 !important;
}

.ant-pro-sider-menu-logo {
  position: relative;
  box-shadow: 2px 2px 9px rgba(178, 178, 178, 0.2);
}

.ant-list-item-meta-avatar {
  align-self: center;
}

.ant-layout-header {
  z-index: unset !important;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-menu-inline {
  flex: 1;
}

.rounded {
  border: 0.75px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 12px;
}

.antd-header-dropdown {
  display: flex;
  align-items: baseline;
  margin-left: auto;
  margin-right: 20px;
  height: 50px;
}

.antd-header-dropdown > .ant-dropdown-trigger {
  display: flex;
  align-items: baseline;
  height: inherit;
}
.page-title .ant-breadcrumb span {
  margin: 0;
}
.page-title .ant-breadcrumb .ant-breadcrumb-separator {
  margin: 0 5px;
}
.ant-table thead > tr > th {
  color: var(--primary);
}
.ant-form .ant-card-head-title {
  color: #000000;
}
.ant-form-item-label > label {
  color: #2b2b2b;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
