.table-search-container {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2em;
  padding: 0.75em 1.5em;
  display: flex;
  justify-content: space-between;
}

.table-search-input {
  padding: 0;
  margin: 0;
  width: 90%;
  border: none;
  outline: none;
}

.table-search-input::placeholder {
  color: #bdbdbd;
}

.overflow-x-auto {
  overflow-x: auto;
}
