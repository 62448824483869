.login-main {
  background: #eee;
}
.login-main .login-wrap {
  display: flex;
  min-height: 100vh;
  width: 100%;
}
.login-main .login-wrap .img-side,
.login-main .login-wrap .form-side {
  height: 100vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7%;
  position: relative;
}
.login-main .login-wrap .img-side {
  background: url('./assets/icons/butlerLoginBg.jpg') center no-repeat;
  background-size: cover;
}
.login-main .login-wrap .img-side:before {
  background: rgba(0, 0, 0, 0.6);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.login-main .login-wrap .img-side * {
  color: #fff;
  position: relative;
}
.login-main .login-wrap .img-side h1 {
  font-size: 30px;
  font-weight: 600;
}
.login-main .login-wrap .img-side p {
  font-size: 24px;
}

.login-main .login-wrap .form-side {
  background-color: #f1f5f6;
}
.login-main .login-wrap .form-side span.ant-input-affix-wrapper {
  border-radius: 4px;
  height: 50px;
}
.login-main .login-wrap .form-side span.ant-input-affix-wrapper input {
  padding-left: 5px;
  border-color: #dae1e3;
}
.login-main .login-wrap .form-side .ant-col.ant-form-item-label label {
  font-size: 15px;
  color: #4c4d4d;
  line-height: 22px;
  font-weight: 600;
}
.login-main .login-wrap .form-side .ant-col.ant-form-item-label label::before {
  display: none;
}
.login-main .login-wrap .form-side .login-form-input {
  border-radius: 4px;
  height: 50px;
  padding-left: 15px;
  border-color: #dae1e3;
}
.login-main .login-wrap .form-side .ant-typography {
  font-weight: 400;
  line-height: 1.2;
  font-size: 26px;
}
.login-main .login-wrap .form-side .ant-form-item {
  margin-bottom: 12px;
}
.login-main .login-wrap .form-side .checkbox-remember-me label {
  margin-left: 2px;
  font-weight: 600;
}
.login-main .login-wrap .form-side .checkbox-remember-me {
  display: block;
  margin: 10px 0;
  font-size: 14px;
  color: #4c4d4d;
  line-height: 22px;
}
.login-main .login-wrap #logo {
  position: absolute;
  top: 5%;
  width: 160px;
}
.login-main .login-wrap .form-side .login-form-button {
  background-color: #000;
  color: white;
  border-radius: 4px;
  border: 0;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
  font-weight: 800;
  font-size: 1rem;
}
@media (max-width: 1024px) {
  .login-main .login-wrap #logo {
    top: 5%;
    width: 160px;
  }
  .login-main .login-wrap .img-content {
    margin-top: 10%;
  }
  .login-main .login-wrap .img-side,
  .login-main .login-wrap .form-side {
    padding: 3%;
  }
}
@media (max-width: 786px) {
  .login-main .login-wrap {
    flex-direction: column;
  }
  .login-main .login-wrap .img-content {
    margin-top: 20%;
  }
  .login-main .login-wrap .img-side,
  .login-main .login-wrap .form-side {
    height: auto;
    width: 100%;
  }
}
