.jobs-page .page-header {
    display: block;
    padding-bottom: 0;
}
.jobs-page .page-header .ant-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.job-detail-drawer .drawer-header {
    padding-bottom: 30px;
}
.job-detail-drawer .drawer-body, .job-detail-drawer .drawer-footer {
    border-top: 1px solid #F2F2F2;
    padding: 15px 5px;
}
.job-detail-drawer .ant-row {
    padding: 15px 0;
}
.job-detail-drawer label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.job-detail-drawer .drawer-body span, .job-detail-drawer .drawer-footer span {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: #828282;
}
.job-detail-drawer .badge {
    border-radius: 5px;
    padding: 8px 16px;
    color: white;
    margin-right: 15px;
}
.job-detail-drawer .drawer-header .header-actions {
    display: flex;
    align-items: center;
}
.job-detail-drawer .badge.completed {
    background: #BDBDBD;
}
.job-detail-drawer .badge.cancelled {
    background: #DF5E67;
}
.job-detail-drawer .badge.pending_schedule {
    background: #F1B13B;
}
.job-detail-drawer .badge.in_progress {
    background: #6ABB98;
}
.job-detail-drawer .badge.scheduled {
    background: #34A6BD;
}
.job-detail-drawer .badge.repeating {
    background: #6ABB98;
}
.job-detail-drawer .badge.no-repeating {
    background: #DF5E67;
}
.limit-title {
    font-size: 0.75em;
    color: #ccc;
    font-style: italic;
}
.job-freq-field-container {
    display: flex;
    align-items: center;
}

.job-freq-field-container > .ant-col > p {
    padding: 0;
    margin: 0;
}