.vendor-profile-page .vendor-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 50px;
}

.vendor-profile-page .vendor-info .vendor-avatar {
  padding: 0 15px;
  display: flex;
  align-items: center;
}
.vendor-profile-page .vendor-avatar-info {
  padding-left: 15px;
}
.vendor-profile-page .vendor-avatar-info .vendor-name {
  font-weight: 500;
  font-size: 18px;
  color: #2b2b2b;
  margin-bottom: 5px;
}
.vendor-profile-page .vendor-avatar-info .vendor-id {
  color: #4f4f4f;
}

.vendor-profile-page .vendor-info-block {
  padding: 0 15px;
}
.vendor-profile-page .vendor-info-block label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.vendor-profile-page .vendor-info-block span {
  display: block;
  font-size: 16px;
  color: #828282;
  margin-top: 10px;
}

.role-perm-heading{
  margin-top: 1em;
    font-weight: bold;
    text-align: center;
}