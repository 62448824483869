
.admin-products-page-btn{
    background-color: #DC4E4E !important;
    color: white !important;
}

td, th {
    vertical-align:top;
}
.ant-list-item-meta-description
{
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    height: 24px;
    line-height: 24px;
}
ul.ant-list-items > li:first-child{
    padding-top: 0;
}
.ant-input-number{
    min-width: 100%;
}

.product-create-field-title-container {
    display: flex;
    margin-top: -2px;
    
}

.product-create-field-title {
    margin: 0;
    padding: 0;
    color: #2B2B2B;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.product-create-field-title-btn {
    background: transparent;
    outline: none;
    border: none;
    color: #1890ff;
    cursor: pointer;
    
}

/* .ant-upload-list-picture-card .ant-tooltip-inner{
    display: none;
} */
 
.ant-upload-list-picture-card .ant-upload-list-item-error{
    border-color: #bebebe !important;
 }
.radioImage .ant-checkbox {
    display: none;
}
.ant-checkbox-wrapper-checked.radioImage .ant-card.ant-card-bordered {
    border-color: #1597FF;
}
.radioImage.disabled:not(.ant-checkbox-wrapper-checked) {
 pointer-events: none;
}
.ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0 !important;
}