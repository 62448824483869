.react-timekeeper {
  position: absolute !important;
  bottom: 36px;
  z-index: 100;
  left: 0;
}
.css-7uwvmz {
  margin-top: 0 !important;
}
.css-v5mcba {
  margin-top: 0 !important;
}
.css-7thaag {
  margin-top: 0 !important;
}
.css-jwqk3 {
  margin-top: 0 !important;
}
.react-timekeeper__done-button {
  display: none !important;
}
.react-timekeeper__clock {
  overflow: hidden !important;
}
.css-11zmfun {
  margin-top: 3px !important;
}
