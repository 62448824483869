.page-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  background: white;
  padding: 16px 24px;
  z-index: 1;
}

.page-title {
  display: flex;
  align-items: baseline;
  min-width: 200px;
}

.page-title span {
  margin-right: 10px;
}

.page-title h4 {
  font-size: 20px;
  margin: 0;
  color: #2b2b2b;
}

.schedule-page .page-title {
  display: block;
}

.schedule-page .page-content {
  position: relative;
  padding: 0;
  margin-top: 0;
}

/*
.
.   Vendor list
.
*/

.vendor-list {
  position: absolute;
  bottom: 0;
  top: 0;
  padding-right: 12px;
  transition: width 0.35s;
}

.vendor-list.fixed {
  position: fixed;
  top: 0;
}

.vendor-list.collapsed {
  width: 27px;
}

.vendor-list.expanded {
  width: 262px;
}
.vendor-list.unassigned-jobs {
  width: 232px;
}

.vendor-list-container {
  width: 100%;
  background: white;
  overflow: hidden;
  height: 100%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
}

.vendor-list-content {
  width: 250px;
  padding: 24px 15px;
}

.vendor-list .btn-collapse {
  position: absolute;
  top: 24px;
  right: 0;
  border-radius: 50%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  border: none;
  width: 24px;
  height: 24px;
  background: white;
}

.vendor-list > .ant-btn {
  background-color: white !important;
}

.vendor-list .btn-collapse span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vendor-list .btn-collapse svg {
  width: 10px;
  height: 10px;
  transition: transform 0.35s;
}

.vendor-list.collapsed .btn-collapse svg {
  transform: rotate(180deg);
}

.vendor-list .list-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.vendor-list .list-header span {
  cursor: pointer;
  margin-right: 15px;
}

.vendor-list .list-header h3 {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 0;
}

.vendor-list .vendor-info {
  padding-top: 15px;
  border-top: 1px solid #f2f2f2;
}

.vendor-list .vendor-info p {
  margin: 0;
  font-size: 13px;
  color: #828282;
}

.vendor-list .vendor-info p:first-child {
  color: #2b2b2b;
  font-weight: 500;
  font-size: 14px;
}

.vendor-list .list-content {
  margin-top: 16px;
}

.vendor-list .list-content .list-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}

.vendor-list .list-content .list-content-header h4 {
  font-size: 16px;
  margin: 0;
}

.vendor-list .list-content .list-content-header button {
  background: #2d9cdb1a;
  color: #2d9cdb;
  border: none;
  border-radius: 4px;
}

.vendor-list .list-content .list-content-body {
  padding: 8px 0;
  border-top: 1px solid #f2f2f2;
}

.vendor-list .list-content .list-content-body .vendor-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  cursor: pointer;
}

.vendor-item:hover {
  color: #1890ff;
}

.vendor-item .ant-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #f1b13b;
  color: white;
  border-radius: 50%;
}

.schedule-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh + 1px);
  transition: margin 0.35s;
}

.vendor-list.expanded ~ .schedule-content {
  margin-left: 262px;
}

.vendor-list.expanded ~ .schedule-content .worker-card {
  width: 24%;
  margin-left: 20px;
  min-width: 265px;
}

/* .vendor-list.expanded+.schedule-content .worker-card:nth-child(3n + 1) {
    margin-left: 0;
} */

.vendor-list.collapsed ~ .schedule-content {
  margin-left: 27px;
}

.vendor-list.collapsed ~ .schedule-content .worker-card {
  width: 24%;
  margin-right: 2%;
  min-width: 265px;
}

.vendor-list.collapsed ~ .schedule-content .worker-card:nth-child(4n + 1) {
  margin-left: 0;
}

/*
.
.   Worker card container
.
*/

.worker-container-border .job-card {
  border: 1px solid #c16f6f !important;
  border-radius: 0.25rem;
  padding: 1px 5px;
}

/*
.
.   Schedule
.
*/

.schedule-content .main-content {
  flex: 1;
  padding-top: 24px;
  padding-left: 8px;
  padding-right: 20px;
}

.schedule-content-header-top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.schedule-content-header-top h3 {
  margin-bottom: 0;
  font-size: 18px;
  color: #2b2b2b;
}

.schedule-content-header-top h4 {
  margin-bottom: 0;
  /* margin-left: 5px; */
  font-size: 16px;
  color: #4f4f4f;
}

.schedule-content-header-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.schedule-content-header-bottom p {
  margin: 0;
  font-size: 13px;
  color: #828282;
}

.schedule-content-header-pagination {
  display: flex;
  align-items: center;
}

.schedule-content-header-pagination span:first-child {
  font-size: 12px;
  color: #828282;
}

.schedule-content-header-pagination span + span {
  margin-left: 10px;
  cursor: pointer;
}

.schedule-content-body {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
}

.loading-overlay {
  padding: 40px 15px;
  text-align: center;
}

.loading-overlay svg {
  width: 32px;
  height: 32px;
}

.job-card {
  border: 1px solid #34a6bd;
  box-shadow: 0 4px 4px rgba(235, 235, 235, 0.5);
  border-radius: 5px;
  cursor: pointer;
  transition: height 0.35s;
  height: 92px;
  overflow-y: hidden;
  user-select: none;
  background: white;
}

.job-card--with-timer-vendor-list {
  min-height: 144px;
}

.job-card--with-timer-worker-list {
  min-height: 94px;
}

.job-card.opened .job-title {
  color: #34a6bd;
}

.job-card-content {
  padding: 10px 8px;
}

.job-card .job-summary {
  padding-bottom: 16px;
}

.job-card .job-time,
.job-card .job-location,
.job-card .job-notes {
  padding: 8px 0;
  border-top: 1px solid #e0e0e0;
}

.job-location {
  padding-bottom: 0px !important;
}

.job-location-unit-postal {
  display: flex;
  justify-content: space-between;
}

.job-location-postal-code {
  text-align: right;
}

.job-counter {
  padding: 8px 0;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-counter-text,
.job-counter-title {
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  color: #828282;
}

.job-counter-title {
  margin-right: 0.5em;
  font-weight: normal;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 0.65em;
  padding: 0.25em 1em;
  background-color: #34a6bd;
}

.job-counter-title--overtime {
  background-color: #e20101c4;
  color: rgb(255, 255, 255);
}

.job-counter-title--completed {
  background-color: #6abb98;
  color: rgb(255, 255, 255);
}

.job-card .job-notes {
  margin-bottom: 0;
}

.job-card .ant-btn {
  margin-top: 10px;
  border: none;
  border-radius: 4px;
}

.job-card .ant-btn:hover {
  opacity: 0.8;
}

.job-card .ant-btn-primary {
  background: #6abb98;
}

.job-card .ant-btn-default {
  background: #2d9cdb;
  color: white;
}

.job-summary-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-summary-row + .job-summary-row {
  padding-top: 10px;
}

.job-summary-row span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.job-summary-row .job-title {
  font-weight: 500;
  font-size: 14px;
  color: #2b2b2b;
  max-width: 140px;
}

.job-summary-row .job-id {
  color: #bdbdbd;
  font-size: 10px;
  max-width: 80px;
}

.job-summary-row .job-desc {
  font-size: 12px;
  color: #828282;
  max-width: 180px;
}

.job-summary-row .job-resume-title {
  font-size: 12px;
  color: #828282;
  max-width: 180px;
}

.job-summary-row .job-resume {
  font-size: 10px;
  color: #4f4f4f;
}

.job-summary-row .job-type {
  font-size: 10px;
  color: #4f4f4f;
}

.job-summary-row .job-company {
  font-size: 11px;
  color: #828282;
  max-width: 160px;
}

.job-card .job-summary-row .job-duration {
  font-size: 11px;
  color: #4f4f4f;
  max-width: 60px;
}

.job-time .preferred-time {
  padding-bottom: 0px;
  text-align: center;
}

.job-time .preferred-time span:first-child {
  display: block;
  font-size: 11px;
  color: #828282;
  padding-bottom: 3px;
}

.job-time .preferred-time span:last-child {
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: #2b2b2b;
}

.job-time .job-time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.job-time .job-time-row label {
  font-size: 13px;
  color: #828282;
}

.job-time .job-time-row span {
  font-size: 13px;
  font-weight: 500;
  color: #2b2b2b;
}

.job-location-row label {
  display: block;
  font-size: 11px;
  color: #828282;
  margin-bottom: 0;
}

.job-location-row span {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #2b2b2b;
}

.job-location-row {
  margin-bottom: 7.5px;
}

.job-notes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.job-notes .job-notes-viewmore {
  display: flex;
  justify-content: space-between;
}

.job-notes .job-notes-row label {
  display: block;
  font-size: 11px;
  color: #828282;
  margin-bottom: 8px;
}

.job-notes .ant-checkbox-wrapper {
  margin-top: 8px;
}

.job-notes .job-notes-row span {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /*color: #2B2B2B;*/
}

.job-notes .ant-btn-link {
  padding: 0 10px;
  margin: 0;
  height: 18px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: #2d9cdb;
}

.worker-card {
  background: #ffffff;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  border-radius: 4px;
  margin: 16px 0;
  padding: 16px;
  max-width: 250px;
}

.worker-card-header {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 15px;
}

.worker-avatar {
  margin-right: 10px;
}

.worker-avatar > span {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.worker-avatar > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.worker-info {
  flex: 1;
  max-width: calc(100% - 50px);
}

.worker-info span {
  font-size: 12px;
  color: #828282;
}

.worker-info .worker-name {
  display: flex;
  align-items: center;
}

.worker-info .worker-name span:first-child {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.worker-card .job-assigned {
  padding: 15px 0;
}

.job-assigned .job-assigned-row {
  display: flex;
  justify-content: space-between;
}

.job-assigned .job-assigned-row label,
.job-assigned .job-assigned-row .worker-container-header {
  font-size: 12px;
  color: #828282;
}

.job-assigned .job-assigned-row span {
  font-size: 12px;
  color: #34a6bd;
}

.job-assigned .job-assigned-row:first-child,
.job-assigned .job-assigned-row:nth-child(2),
.job-assigned .job-assigned-row:nth-child(3),
.job-assigned .job-assigned-row:nth-child(4) {
  padding-bottom: 10px;
}

.worker-schedule {
  padding-top: 15px;
}

.worker-schedule--disabled {
  border: 1px solid #828282;
  padding: 1em;
  border-radius: 0.25rem;
  background-color: #fafafa;
  cursor: not-allowed;
}

.worker-schedule .schedule-row {
  position: relative;
  padding-top: 9px;
}

.schedule-row > label {
  position: absolute;
  top: 0;
  font-size: 12px;
  color: #828282;
}

.schedule-row .schedule-row-content {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  padding: 8px 0;
  border-top: 1px solid #f2f2f2;
}

.schedule-row .job-card,
.job-card.dragging {
  border-color: rgb(106, 187, 152);
  height: 66px;
}

#worker-container .job-card {
  margin: 5px 0;
}
.worker-container-row {
  min-height: 5px !important;
}

.schedule-row .job-card-content,
.job-card.dragging .job-card-content {
  padding: 8px;
}

.schedule-row .job-summary,
.job-card.dragging .job-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px !important;
}

.schedule-row .job-summary-row,
.job-card.dragging .job-summary-row {
  display: none;
}

.schedule-row .job-time .job-time-row label {
  font-size: 11px;
}

.job-card .job-main-info {
  display: none;
  width: calc(100% - 60px);
}

.job-card .job-main-info .job-title {
  font-weight: 600;
  font-size: 13px;
  color: #2b2b2b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-card .job-main-info .job-desc {
  font-size: 11px;
  color: #bdbdbd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-card .job-summary > .job-duration {
  display: none;
  font-size: 10px;
  color: #828282;
  text-align: right;
}

.schedule-row .job-card .job-main-info,
.job-card.dragging .job-main-info,
.schedule-row .job-summary > .job-duration,
.job-card.dragging .job-summary > .job-duration {
  display: block;
}

.schedule-row .job-card .ant-btn {
  font-size: 11px;
}

.drop-preview {
  background: rgba(106, 187, 152, 0.1);
  height: 55px;
  border: 1px solid #6abb98;
  border-radius: 4px;
}

.job-cancel-modal {
  text-align: center;
}

.job-cancel-modal p:first-child {
  color: #2d9cdb;
  font-weight: 500;
  font-size: 18px;
}

.job-cancel-modal p:nth-child(2) {
  font-size: 14px;
  color: #828282;
}

.job-checked-in {
  background-color: #b2dfdb;
}

.job-not-checked-in {
  background-color: #fff;
}

.job-not-checked-in > .job-card-content > .job-summary > .job-summary-row {
  padding-top: 3px;
}

.job-checked-out {
  background-color: #f5f5f5;
}

.job-status-cancelled {
  background-color: #e57373;
  border: 1px solid #d32f2f;
}

.job-time-difference {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
}

.job-time-difference-time {
  font-size: 10px;
}
.date-range label {
  text-align: start;
}

.job-checked-in .job-title,
.job-checked-in .job-id,
.job-checked-in .job-desc,
.job-checked-in .job-type,
.job-checked-in .job-company,
.job-checked-in .job-duration,
.job-checked-in .preferred-time > span,
.job-checked-in .job-time-row > label,
.job-checked-in .job-time-row > span,
.job-checked-in .job-location-row > label,
.job-checked-in .job-location-row > span,
.job-checked-in .job-notes-viewmore > label,
.job-checked-in .job-notes-row > span,
.job-checked-in .job-notes-viewmore > .ant-btn,
.job-checked-in .job-card-content > .job-counter > .job-counter-text,
.job-checked-in .job-card-content > .job-time-difference,
.job-checked-in .ant-checkbox-wrapper > span {
  color: #00897b !important;
}

.job-checked-in .job-desc,
.job-checked-in .job-card-content > .job-time-difference > p:first-child,
.job-checked-in .preferred-time > span:first-child,
.job-checked-in .job-time-row > label,
.job-checked-in .job-notes-viewmore > .ant-btn,
.job-checked-in .job-location-row > label {
  color: #25ada0 !important;
}

.job-status-cancelled .job-title,
.job-status-cancelled .job-id,
.job-status-cancelled .job-desc,
.job-status-cancelled .job-type,
.job-status-cancelled .job-company,
.job-status-cancelled .job-duration,
.job-status-cancelled .preferred-time > span,
.job-status-cancelled .job-time-row > label,
.job-status-cancelled .job-time-row > span,
.job-status-cancelled .job-location-row > label,
.job-status-cancelled .job-location-row > span,
.job-status-cancelled .job-notes-viewmore > label,
.job-status-cancelled .job-notes-row > span,
.job-status-cancelled .job-notes-viewmore > .ant-btn,
.job-status-cancelled .job-card-content > .job-counter > .job-counter-text,
.job-status-cancelled .job-card-content > .job-time-difference,
.job-status-cancelled .ant-checkbox-wrapper > span {
  color: #fff !important;
}

.hightlighter-1 {
  border-left: 4px solid #408dbf !important;
}

.hightlighter-2 {
  border-left: 4px solid #5e862d !important;
}

.schedule-content-header-top > .header-dash {
  margin-left: 10px;
  margin-right: 10px;
}

.route-name {
  display: none;
}

.note-group {
  margin: 15px 0;
}

.note-group > h3 {
  margin: 0;
  margin-bottom: 5px;
}

.note-group > textarea.ant-input.ant-input-disabled {
  background-color: #f5f5f5;
  cursor: default !important;
  opacity: 1;
  color: inherit;
}

@media screen and (max-width: 576px) {
  .page-header {
    padding: 0;
  }

  .page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    width: 100%;
    border-bottom: 2px solid #ececec;
    position: fixed;
    top: 64px;
    background-color: white;
  }

  .header-drawer-icon {
    flex: 1;
  }

  .route-name {
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 24px;
    flex: 3;
  }

  .main-header {
    flex: 1;
    max-width: 55px;
  }

  .page-title h4 {
    /* font-size: 18px;
        text-align: center;
        padding: 0.5em 0;
        border-bottom: 2px solid #ececec; */
    display: none;
  }

  .page-date-selector {
    width: 70%;
    margin: 1em auto;
  }

  .page-date-selector > .ant-col > .ant-picker > .ant-picker-input > input {
    text-align: center;
  }

  .page-btn > .anticon {
    margin-right: 0;
  }

  .schedule-content-body {
    flex-wrap: unset;
    overflow-x: scroll;
  }

  .vendor-list.fixed {
    position: fixed;
    top: 126px;
  }

  .vendor-list {
    z-index: 1;
  }

  .vendor-list.expanded ~ .schedule-content {
    margin-left: 0;
  }

  .vendor-list.expanded ~ .schedule-content .worker-card {
    width: 24%;
    margin-left: 20px;
    min-width: 265px;
  }

  .vendor-list.expanded ~ .schedule-content .worker-card:nth-child(3n + 1) {
    margin-left: 0;
  }

  .vendor-list.collapsed ~ .schedule-content {
    margin-left: 27px;
  }

  .vendor-list.collapsed ~ .schedule-content .worker-card {
    width: 24%;
    margin-right: 2%;
    min-width: 265px;
  }

  .vendor-list.collapsed ~ .schedule-content .worker-card:nth-child(4n + 1) {
    margin-left: 0;
  }

  .schedule-content .main-content {
    flex: 1;
    padding-top: 22px;
    padding-left: 0;
    padding-right: 20px;
  }

  .schedule-content-header {
    padding-left: 16px;
  }

  .schedule-content-header-top {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .schedule-content-header-top > h3 {
    font-size: 1.25em;
    font-weight: bold;
  }

  .schedule-content-header-top > h4 {
    font-size: 1em;
    font-weight: 700;
    color: #4f4f4f;
  }

  .schedule-content-header-top > .header-dash {
    display: none;
  }

  .schedule-page > .page-content {
    margin-top: 126px;
    z-index: 0;
  }
  .notes-drawer.expanded {
    width: 340px !important;
  }
}

.notes-drawer {
  position: absolute;
  bottom: 0;
  top: 0;
  padding-left: 12px;
  transition: width 0.35s;
  right: 0;
}

.notes-drawer.fixed {
  position: fixed;
  top: 0;
}

.notes-drawer.collapsed {
  width: 27px;
}

.notes-drawer.expanded {
  width: 362px;
}

.notes-drawer-container {
  width: 100%;
  background: white;
  padding: 10px;
  overflow: hidden;
  height: 100%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
}

.notes-drawer-container,
.header {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.notes-drawer-container ~ .header > h3 {
  font-size: 18px;
  line-height: 21px;
}
.notes-drawer-content {
  width: 330px;
  padding: 24px 10px;
}

.notes-drawer .btn-collapse {
  position: absolute;
  top: 24px;
  left: 0;
  border-radius: 50%;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  border: none;
  width: 24px;
  height: 24px;
  background: white;
}

.notes-drawer > .ant-btn {
  background-color: white !important;
}

.notes-drawer .btn-collapse span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes-drawer .btn-collapse svg {
  width: 10px;
  height: 10px;
  transition: transform 0.35s;
}

.notes-drawer.collapsed .btn-collapse svg {
  transform: rotate(180deg);
}

.notes-drawer.expanded ~ .schedule-content {
  margin-right: 362px;
}
ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.dropdown-menu
  > li {
  margin: 2px;
}

.schedular-card-dropdown > .dropdown-menu {
  position: absolute;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  z-index: 1000;
  outline: none;
  right: 0;
  margin-right: 25px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.schedular-card-dropdown > .dropdown-menu > .ant-menu-item {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.schedular-card-dropdown
  > .dropdown-menu
  > .ant-menu-item
  > .ant-menu-title-content {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.draft-job {
  background: repeating-linear-gradient(
    45deg,
    #ddd,
    #ddd 10px,
    #fff 10px,
    #fff 20px
  );
}
.paused-job {
  background-color: #9b5b9b;
}
.ant-menu-title-content .anticon svg {
  margin-right: 5px;
}
.paused-job .ant-menu-title-content {
  color: #000 !important;
}

.paused-job
  *:not(.paused-job ul.ant-menu span, .paused-job
    ul.ant-menu
    span
    svg, .paused-job ul.ant-menu span path, .paused-job
    .worker-location.permanent-assign-selectBox-div
    *) {
  color: white !important;
}

.color-legand {
  display: flex;
  align-items: flex-end;
}
.color-legand .color-box {
  margin: 0 3px;
  padding: 5px;
}
.color-legand .color-box > p {
  padding-top: 5px;
}
.color-legand .color-box .color-block {
  color: transparent;
}
