.job-profile-page .job-info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.job-profile-page .job-title .job-info-block {
  padding: 0;
}
.job-profile-page .job-other-info {
  display: flex;
}
.job-profile-page .job-info-block {
  padding: 0 15px;
}
.job-profile-page .job-info-block label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.job-profile-page .job-info-block span {
  display: block;
  font-size: 16px;
  color: #828282;
  margin-top: 10px;
}

.job-info-top {
  border-bottom: 1px solid #f2f2f2;
}
.job-detail-block {
  padding: 15px;
}
.job-detail-block label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
  margin-bottom: 10px;
}
.job-detail-block span {
  display: block;
  color: #828282;
  font-size: 16px;
}

.page-sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.page-sub-header h4 {
  margin: 0;
  font-size: 20px;
}

.assign-worker-modal .ant-modal-header {
  border: none;
}
.assign-worker-modal .ant-modal-footer {
  border: none;
}
.assign-worker-modal .date-range {
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}
.assign-worker-modal label {
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.assign-worker-modal .form-row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.assign-worker-modal .form-row > span {
  margin: 0 24px;
  font-size: 16px;
  color: black;
}
.assign-worker-modal .assign-worker {
  padding-top: 30px;
}
.assign-worker-modal .form-group + .form-group {
  margin-top: 24px;
}
