.client-profile-page .client-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
}

.client-profile-page .client-info .user-avatar {
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.client-profile-page .user-avatar-info {
    padding-left: 15px;
}
.client-profile-page .user-avatar-info .user-name {
    font-weight: 500;
    font-size: 18px;
    color: #2B2B2B;
    margin-bottom: 5px;
}
.client-profile-page .user-avatar-info .client-id {
    color: #4F4F4F;
}

.client-profile-page .user-info-block {
    padding: 0 15px;
}
.client-profile-page .user-info-block label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #2B2B2B;
}
.client-profile-page .user-info-block span {
    display: block;
    font-size: 16px;
    color: #828282;
    margin-top: 10px;
}


.delete-address-modal .ant-modal-body {
    text-align: center;
}
.delete-address-modal .ant-modal-footer {
    border: none;
    padding: 24px;
}
.delete-address-modal p {
    margin: 0;
}
.delete-address-modal .address-info {
    padding: 30px 0;
    color: #2B2B2B;
}
.delete-address-modal .main-message {
    max-width: 350px;
    margin: auto;
    font-weight: 500;
    font-size: 18px;
    color: #2D9CDB;
}
.delete-address-modal .ant-modal-footer .ant-btn {
    width: 120px;
    border-radius: 5px;
    border: none;
}
.delete-address-modal .ant-modal-footer .ant-btn-primary {
    background: #2D9CDB;
}

.extra-header {
    background: #FFFFFF;
    padding: 30px 40px 0 40px;
}
.client-profile-page .extra-header .client-info {
    justify-content: space-between;
    padding-bottom: 15px;
}