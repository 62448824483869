.avatar-uploader .ant-upload-select {
  width: 100px;
  height: 100px;
  margin: 0;
  border: none;
  background: transparent;
  cursor: default;
}
.avatar-uploader .ant-upload-select > span.ant-upload {
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.cross {
  line-height: 0;
  font-size: 3em;
}
/* .ant-upload .ant-upload-select-picture-card{
  width: 60px !important;
    height: 60px !important;
} */

/* 
.ant-upload-list .ant-upload-list-picture-card 
.ant-upload .ant-upload-select .ant-upload-select-picture-card{
} */
.ant-upload-picture-card-wrapper
  .ant-upload-list
  .ant-upload-list-picture-card-container {
  width: 80px;
  height: 80px;
}
.error .ant-upload-select-picture-card {
  border: 1px dashed red;
}
.image-alert-message {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1.5715;
  margin-top: -8px;
}

/* @media only screen and (max-width: 600px) {
  .ant-upload.ant-upload-select-picture-card{
    width: 100% !important;
  }
} */
