td,
th {
  vertical-align: top;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  height: 24px;
  line-height: 24px;
}
ul.ant-list-items > li:first-child {
  padding-top: 0;
}
.ant-input-number {
  min-width: 100%;
}
.product-create-field-title-container {
  display: flex;
  margin-top: -2px;
}
.radioImage{
  height: 150px !important;
}