@import url('https://fonts.googleapis.com/css2?family=Roboto');

:root {
  --color1: #2f80ed;
  --color2: #2d9cdb;
  --color3: #6abb98;
  --color4: #2b2b2b;
  --color5: #828282;
  --color6: #f1b13b;
  --color7: #df5e67;
}

.transaction-container {
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.transaction-content-container {
  margin: 2.5em;
}

.font-bold {
  font-weight: bold;
  letter-spacing: 1px;
}

.tran-type-debit,
.tran-status-success {
  color: var(--color3);
}

.tran-type-credit {
  color: var(--color7);
}

.link:hover {
  text-decoration: underline;
}

.table-column-title {
  color: var(--color1);
  font-weight: normal;
}

.link-disabled,
.link-disabled:hover {
  color: var(--color5);
  cursor: not-allowed;
}

.hide-column {
  display: none;
}

.order-item-table-heading-container {
  padding: 1em 0 0.5em 0;
}

.order-item-table-heading {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 1.25em;
}

.order-item-table-text {
  padding: 0;
  margin: 0;
  margin-top: 1em;
  font-size: 0.875em;
}

.order-item-table-footer-container {
  display: flex;
}

.order-item-table-footer-text {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 1.15em;
}

.oi-table-footer-heading {
  flex: 4.7;
  text-align: right;
  padding-right: 2em;
}

.oi-table-footer-text {
  flex: 1;
}

.color-yellow {
  color: var(--color6);
}

.color-green {
  color: var(--color3);
}

.color-red {
  color: var(--color7);
}

.color-gray {
  color: var(--color5);
}
.credit:first-child {
  color: #dc4e4e;
}
.debit:first-child {
  color: rgb(106, 187, 152);
}
