.reports-page .page-header {
  display: block;
  padding-bottom: 0;
}
.reports-page .page-header .ant-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.reports-page .page-content {
  margin-top: 0;
}

.reports-page .filters-container {
  margin-bottom: 2em;
  padding-bottom: 2em;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e4e3e3;
}

.reports-page .filters-container .filter-search {
  flex: 1;
  max-width: 300px;
}

.reports-page .filters-container .vendor-select {
  margin-right: 1em;
}

.reports-page .filters-container .filter-search .ant-select-selector {
  height: auto;
  padding: 10px 15px !important;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  align-items: center !important;
}

.reports-page .filters-container .ant-picker-large {
  border: 1px solid #f2f2f2;
}

.reports-page .filters-container .ant-picker-focused {
  border-color: #40a9ff;
}

.reports-page
  .filters-container
  .ant-form-item-with-help
  .ant-form-item-control
  .ant-form-item-explain {
  margin-top: 0.25em;
  margin-left: 1.25em;
}

.reports-page .filters-container .date-picker {
  flex: 1;
  padding: 10px 15px !important;
  border-radius: 5px;
  max-width: 232px;
  margin-left: 1em;
  align-items: center !important;
}

.reports-page .filters-container .client-address-select {
  flex: 1;
  border-radius: 5px;
  max-width: 300px;
  margin-left: 1em;
}

.reports-page .filters-container .date-picker:hover {
  border-color: #40a9ff;
}

.reports-page .filters-container .date-picker .ant-picker-input input {
  font-size: 14px;
  margin-left: 2.5em;
}

.reports-page .filters-container .client-address-select .ant-select-selector {
  padding: 6.5px 15px !important;
}

/* .reports-page .filters-container .client-address-select .ant-select-selector .ant-select-selection-item {
    display: none;
} */

.reports-page
  .filters-container
  .filter-search
  .ant-select-selector
  .ant-select-selection-search {
  margin-left: 2.5em;
}

.reports-page .filters-container .filter-search-btn {
  margin-left: 1.25em;
  width: 100px;
  margin-top: 1px;
}

.reports-page
  .filters-container
  .filter-search
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input {
  height: 100%;
}

.reports-page
  .filters-container
  .filter-search
  .ant-select-selector
  .ant-select-selection-placeholder {
  margin-left: 2.5em;
  line-height: unset;
}

.reports-page
  .filters-container
  .client-address-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  margin-left: 0.5em;
  line-height: unset;
}

.reports-page
  .filters-container
  .filter-search
  .ant-select-selector
  .ant-select-selection-item {
  margin-left: 2.5em;
  line-height: unset;
}

.reports-page
  .filters-container
  .client-address-select
  .ant-select-selector
  .ant-select-selection-item {
  margin-left: 0;
  line-height: unset;
}

.reports-page
  .filters-container
  .client-address-select
  .ant-select-selector
  .ant-select-selection-search {
  margin-left: 0;
  line-height: unset;
}

.reports-page .filters-container .filter-search .ant-select-arrow {
  top: 46%;
  right: 0;
  left: 18px;
}

.reports-page .filters-container .vendor-select .ant-select-arrow {
  top: 35%;
}

.reports-page
  .filters-container
  .date-picker
  .ant-picker-input
  .ant-picker-suffix {
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.reports-page
  .filters-container
  .filter-search
  .ant-select-arrow
  .anticon-search
  svg,
.reports-page
  .filters-container
  .date-picker
  .ant-picker-input
  .ant-picker-suffix
  .anticon-calendar
  svg {
  height: 1.25em;
  width: 1.25em;
  fill: #2d9cdb;
}

body #root .ant-tabs-tab {
  font-size: 16px;
  margin: 0;
  padding: 15px;
}
body #root .ant-tabs-tab-active {
  color: #2d9cdb;
  font-weight: 500;
  border-bottom: 3px solid #2d9cdb;
}

.table-no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vmin;
}

.table-no-data-container > .no-data-message-container {
  margin-top: 0.5em;
}

.table-no-data-container > .no-data-message-container > .no-data-message {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  color: #4f4f4f;
  font-size: 0.9em;
}

.customer-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 1em;
}

.customer-info-container > .customer-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.customer-info-container > .customer-period-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.customer-info-container > .customer-info > .customer-info-name-container {
  display: flex;
  flex-direction: column;
  margin-left: 1.25em;
  width: 100%;
}

.customer-info-container
  > .customer-info
  > .customer-info-name-container
  > .page-date-selector {
  width: 200px;
  margin-top: 5px;
}
.page-date-selector .ant-picker {
  padding: 4px 0px !important;
}
.customer-info-container
  > .customer-info
  > .customer-info-name-container
  > .page-date-selector
  > .ant-col
  > .ant-picker
  > .ant-picker-input
  > input {
  text-align: center;
}

.customer-info-container
  > .customer-info
  > .customer-info-name-container
  > .customer-info-name,
.customer-period-info > .customer-period-heading {
  padding: 0;
  margin: 0;
  color: #2b2b2b;
  font-size: 1.25em;
  font-weight: bold;
}

.customer-period-info > .customer-period-heading {
  font-size: 0.85em;
}

.customer-info-container
  > .customer-info
  > .customer-info-name-container
  > .customer-info-id,
.customer-period-info > .customer-period-date {
  padding: 0;
  margin: 0;
  color: #4f4f4f;
  font-size: 0.85em;
  font-weight: normal;
}
