body .ant-btn-primary {
  border-radius: 4px;
  background: #2d9cdb;
  border-color: #2d9cdb;
}

body .ant-btn-default {
  border-color: #2d9cdb;
  color: #2d9cdb;
  border-radius: 4px;
}
.page-header .page-header-actions {
  display: flex;
  align-items: center;
}

.page-header .page-header-actions .page-header-action,
.page-header .page-header-actions button {
  margin-right: 15px;
}
.page-header .page-header-actions .page-header-action svg {
  color: #bdbdbd;
  font-size: 18px;
  cursor: pointer;
}
.page-header .page-header-actions .ant-input-search {
  width: 100%;
  padding: 6px 18px;
  border: 1px solid #f2f2f2;
  border-radius: 25px;
  box-shadow: 2px 4px 9px rgba(199, 199, 199, 0.5);
  box-sizing: border-box;
}

.page-content {
  padding: 30px;
}

.ant-table-action-cell {
  display: flex;
  align-items: center;
}
.ant-table-action-cell svg {
  color: #bdbdbd;
  font-size: 18px;
}
.ant-table-action-cell span + span {
  margin-left: 15px;
}

.delete-user-modal .ant-modal-body {
  text-align: center;
}
.delete-user-modal .ant-modal-footer {
  border: none;
  padding: 24px;
}
.delete-user-modal p {
  margin: 0;
}
.delete-user-modal .user-info {
  margin: 24px;
}
.delete-user-modal .username {
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.delete-user-modal .main-message {
  max-width: 350px;
  margin: auto;
  font-weight: 500;
  font-size: 18px;
  color: #2d9cdb;
}
.delete-user-modal .ant-modal-footer .ant-btn {
  width: 120px;
  border-radius: 5px;
  border: none;
}
.delete-user-modal .ant-modal-footer .ant-btn-primary {
  background: #2d9cdb;
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.drawer-header h3 {
  color: #2b2b2b;
  font-size: 22px;
  margin: 0;
}
.drawer-header svg {
  color: #bdbdbd;
  font-size: 18px;
}
.drawer-header .action-primary svg {
  color: #2d9cdb;
  margin-right: 15px;
}
.drawer-body .user-avatar {
  text-align: center;
  padding: 30px 0;
}
.drawer-body .user-avatar .avatar-uploader {
  width: auto;
}
.drawer-body .username {
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
}
.drawer-body .user-status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-body .user-status .dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.drawer-body .user-status .dot.active {
  background: #6abb98;
}
.drawer-body .user-status .dot.inactive {
  background: #f1b13b;
}
.drawer-body .user-status .dot.suspended {
  background: #df5e67;
}
.drawer-body .user-status span:last-child {
  font-size: 16px;
  color: #2b2b2b;
  margin-left: 10px;
  text-transform: capitalize;
}
.drawer-body .user-info-row {
  padding: 10px;
}
.drawer-body .user-info-row label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .user-info-row span {
  display: block;
  font-size: 16px;
  color: #828282;
}
.drawer-body .avatar-uploader .ant-upload-select {
  border: none;
  margin: 0;
  background: transparent;
}

.drawer-body .form-group {
  padding: 6px 0;
}
.drawer-body .form-group > label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body .form-group > span {
  font-size: 16px;
  color: #2b2b2b;
}
.drawer-body form .ant-btn-block {
  margin-top: 15px;
}
.drawer-body form .ant-btn-primary {
  background: #2d9cdb;
  border-radius: 7px;
  border-color: #2d9cdb;
  height: 42px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}
.drawer-body .password-reset-form {
  margin-top: 30px;
}

.cursor-pointer {
  cursor: pointer;
}
