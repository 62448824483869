.announcement-tab-container > .ant-card-body {
    padding: 1em 0 0 0;
}

.announcement-tabs > .ant-tabs-bar {
    padding: 0;
    margin: 0;
}

.announcement-tabs > .ant-tabs-bar {
    padding: 0;
    margin: 0;
}

.tab-container {
    margin: 2em !important;
    border-radius: 7.5px !important;
}

.card-heading {
    padding: 0;
    margin: 0;
    color: #4f4f4f;
    font-weight: bold;
    padding-bottom: 17px;
    border-bottom: 1px solid #f2f2f2;
}

.field-container {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}

.field-label {
    padding: 0;
    margin: 0;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 0.25px;
    margin-bottom: 0.25em;
}

.field-label-required {
    color: #2d9cdb;
}

.text-area-char-count {
    padding: 0;
    margin: 0;
    margin-top: 0.5em;
    text-align: right;
    color: #bdbdbd;
}

.action-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
}

.btn {
    width: 6em;
    margin-left: 1em;
}

.btn-reset {
    color: #2d9cdb;
}

.table-column-title {
    color: #2f80ed;
    font-weight: 800;
}

.mt2 {
    margin-top: 2em;
}

.checkbox-c {
    font-weight: 700;
    color: #000;
}

.modal-header-title,
.modal-header-text {
    padding: 0;
    margin: 0;
}

.modal-header-title {
    font-size: 1.25em;
    font-weight: bold;
}

.modal-header-text {
    font-size: 0.875em;
    margin-top: 0.5em;
}

.modal-body-container {
    display: flex;
    flex-direction: column;
}

.modal-body-head-container {
    display: flex;
}

.modal-body-head {
    flex: 1;
}

.modal-body-title {
    font-weight: bold;
}

.text-ellipses {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 575px;
    white-space: nowrap;
}

.recipient-status-pending {
    color: #828282;
    font-weight: 500;
}

.recipient-status-delivered {
    color: #6abb98;
    font-weight: 500;
}
