@import url('https://fonts.googleapis.com/css2?family=Roboto');

:root {
  --color1: #2f80ed;
  --color2: #2d9cdb;
  --color3: #6abb98;
  --color4: #2b2b2b;
  --color5: #828282;
  --color6: #f1b13b;
  --color8: #f2f2f2;
}

.dashboard-container {
  width: 95%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  padding-top: 4em;
}

.font-bold {
  font-weight: bold;
  letter-spacing: 1px;
}

.in-transit {
  color: var(--color6);
}

.completed {
  color: var(--color3);
}

.not-started {
  color: var(--color5);
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
}

.header-title {
  font-size: 1.25em;
  color: var(--color4);
}

.header-timestamp {
  font-size: 1.25em;
  display: flex;
  align-items: center;
}

.dashboard-time {
  padding: 0;
  margin: 0;
  font-size: 0.8em;
}

.statistics-container {
  /* border: 1px solid red; */
  overflow: hidden;
}

/* .job-feed-container {
    border: 1px solid blue;
} */

.main-table {
  margin-top: 2em !important;
  border-radius: 10px !important;
}

.ant-card-head-title {
  color: var(--color1);
  font-weight: 800;
}

.stats_job_feed_container {
  flex-direction: row;
  min-height: 700px;
}

.stats-cards-container {
  display: flex;
  /* height: 135px; */
  flex-direction: column;
  align-items: center;
}

.stats-card {
  flex: 1;
  margin-right: 0.85em !important;
  border-radius: 10px !important;
  display: flex;
  padding: 0;
}

.stats-card:last-child {
  margin-right: 2em !important;
}

.active-worker-list-header {
  padding: 1.25em 1.75em;
}

.stats-card > .ant-card-body {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 1em 2em 1.75em 2em;
  margin: 0;
}

.stats-card-data {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stats-card-num {
  font-size: 1.5em;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.stats-card-data-title {
  font-size: 0.875em;
  padding: 0;
  margin: 0;
  color: var(--color4);
  font-weight: 500;
  margin-right: 0.25em;
}

.stats-card-icon-container {
  flex: 1;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-card-icon {
  color: var(--color1);
  width: 2.5em;
}

.job-feed-card {
  flex: 1;
  border-radius: 10px !important;
  height: 100%;
}

.job-feed-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.75em;
}

.job-feed-header-title {
  font-size: 1em;
  color: var(--color1);
  font-weight: 600;
  margin: 0;
}

.job-feed-header-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-feed-header-icon {
  font-size: 1.25em;
  color: var(--color2);
  cursor: pointer;
  width: 20px;
}

.feed-count-section {
  display: flex;
  /* margin-top: 1.5em; */
}

.feed-count-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color4);
}

.feed-count-title {
  font-size: 0.875em;
  padding: 0;
  margin: 0;
}

.feed-count {
  font-size: 1.75em;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.divider {
  height: 1px;
  background-color: var(--color8);
  margin-top: 2em;
}

.timeline-container {
  /* margin-top: 2em; */
  /* padding-top: 1em; */
  max-height: 800px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.timeline-container::-webkit-scrollbar {
  display: none;
}

.avatar-container {
  margin-left: 1em;
}

.mr-2 {
  margin-right: 5px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.timeline-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.timeline-content {
  display: flex;
  align-items: center;
  padding: 1.25em 1.75em;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
}

.timeline-avatar {
  border: 1px solid var(--color2);
  min-width: 2em;
  min-height: 2em;
}

.timeline-details-container {
  margin-left: 1em;
}

.timeline-details-name,
.timeline-details-check-text {
  padding: 0;
  margin: 0;
}

.timeline-details-name {
  font-size: 1em;
  /* font-weight: bold; */
  color: var(--color4);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.timeline-details-secondary-text {
  font-size: 0.8em;
  font-weight: 400;
  color: var(--color5);
  padding-top: 5px;
}

.timeline-details-check-text {
  font-size: 1em;
  color: var(--color5);
  word-break: break-all;
}

.timeline-details-check-text-link {
  font-weight: 800;
  color: var(--color2);
}

.timeline-timestamp {
  position: absolute;
  right: 1.25em;
  bottom: 0;
  font-size: 0.85em;
  color: #BDBDBD;
}

.map-container {
  margin-right: 2em;
  margin-top: 1em;
  border-radius: 10px;
  flex: 1;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.map-container > .job-feed-header {
  background-color: white;
  border-radius: 10px 10px 0 0;
  padding: 1em 2em 1em 1em;
}

.map-main {
  flex: 1;
  height: 100%;
  border-radius: 10px;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
}

.table-column-title {
  color: var(--color1);
  font-weight: 800;
}

.map-filters-container {
  width: 97%;
  border-radius: 10px !important;
  margin-right: 2em;
  margin-top: 1em !important;
  align-self: flex-start;
}

.map-filters-container > .ant-card-body {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  display: flex !important;
  align-items: center;
}

.filter-options-container {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}

.checkbox-option {
  display: flex !important;
  align-items: center;
}

.filter-option {
  display: flex;
  align-items: center;
}

.filter-dot {
  max-width: 8px;
  max-height: 8px;
  min-width: 8px;
  min-height: 8px;
  background-color: #828282;
  border-radius: 50%;
  margin-right: 0.4em;
}

.filter-dot--primary {
  background-color: #2d9cdb;
}

.filter-dot--danger {
  background-color: #df5e67;
}

.filter-option-label {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0.75rem;
}

.map-filters-container > .ant-card-body > .filter-label {
  margin-right: 1em;
}

@media screen and (max-width: 1024px) {
  .stats-card:last-child {
    margin-right: 0;
  }

  .stats-card {
    max-height: 10em;
  }

  .map-container {
    margin-right: 0;
  }

  .statistics-container {
    height: 70vh;
  }

  .job-feed-container {
    margin-top: 2.25em;
  }
}

@media screen and (max-height: 768px) {
  .statistics-container {
    /* height: 100vh; */
    min-height: 60em;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .map-container {
    margin-bottom: 2em;
    margin-right: 0;
  }

  .stats-card:last-child {
    margin-right: 0 !important;
  }

  .statistics-container {
    height: inherit;
  }

  .job-feed-container {
    margin-top: 0.5em;
  }

  .map-filters-container {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .map-container {
    margin-bottom: 0;
    margin-right: 0;
  }

  .stats-card:last-child {
    margin-right: 0 !important;
  }

  .statistics-container {
    height: auto;
  }

  .map-filters-container {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .stats-card {
    margin-right: 0.5em;
  }
  .stats-card:last-child {
    margin-right: 0 !important;
  }

  .statistics-container {
    height: auto;
  }

  .map-filters-container {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .statistics-container {
    height: inherit;
    margin-bottom: 1.25em;
  }
  .stats-cards-container {
    display: flex;
    flex-direction: column;
    height: initial;
  }
  .stats-card {
    margin-right: 0;
    margin-bottom: 2em;
  }
  .header-container {
    flex-direction: column;
  }
  .header-timestamp {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    margin-top: 1.25em;
    background-color: white;
    width: 100%;
    padding: 1em 0;
    border-radius: 5px;
  }
  .dashboard-datepicker {
    margin-top: 0.75em;
  }
  .map-container {
    margin-top: 0;
    max-height: 300px;
  }
  .stats-card-container {
    display: none !important;
  }
  .job-feed-container {
    margin-top: 0;
  }
  .job-feed-card {
    border-radius: 5px !important;
  }
  .main-table {
    margin-top: 1em !important;
    border-radius: 5px !important;
  }
  .divider {
    margin-top: 1em;
  }
  .timeline-details-name {
    font-size: 0.85em;
  }
  .timeline-details-check-text {
    font-size: 0.85em;
  }
  .stats-card:last-child {
    margin-right: 0 !important;
  }
  .job-feed-header-title {
    font-size: 1em;
  }
  .job-feed-header-icon {
    height: 21px;
  }
  .work-status-table-title {
    font-size: 0.85em;
  }
  .map-filters-container {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 1em !important;
  }
  .map-filters-container > .ant-card-body {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .map-filters-container > .ant-card-body > .filter-label {
    font-weight: bold;
  }
  .filter-options-container {
    flex-wrap: wrap;
  }
  .checkbox-option {
    margin: 0 !important;
    margin-top: 10px !important;
    min-width: 50% !important;
  }
}

.map_pin_job-tooltip_container {
  width: 244px;
  height: 120px;
  padding: 0;
  margin: 0 0.5em;
}

.map_pin_job-tooltip_header,
.map_pin_job_duration_container,
.map_pin_job_user_info_container {
  display: flex;
  align-items: center;
}

.map_pin_job_user_info_container {
  align-items: flex-start;
}

.map_pin_job_user_info {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

.map_pin_job_duration_container {
  margin: 0.5em 0;
}

.map_pin_job-tooltip_header {
  justify-content: space-between;
}

.map_pin_job-tooltip_header_title,
.map_pin_job_time,
.map_pin_job_duration_title,
.map_pin_job_duration_text,
.map_pin_job_user_info_name,
.map_pin_job_user_info_addr,
.map_pin_job_user_info_tag {
  padding: 0;
  margin: 0;
  font-size: 1.25em;
}

.map_pin_job_duration_title,
.map_pin_job_duration_text,
.map_pin_job_user_info_addr,
.map_pin_job_user_info_tag {
  font-size: 1.15em;
  color: var(--color4);
}

.map_pin_job-tooltip_header_title,
.map_pin_job_user_info_name {
  font-weight: bold;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: var(--color5);
  border-radius: 50%;
  margin: 0 0.5em;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-picker-dashboard > .ant-picker-input > input {
  text-align: center;
  width: 75px;
}

.job-feed-card > .ant-card-body {
  padding: 0 !important;
}

@media (max-width: 576px) {
  .header-container {
    padding: 1em;
    margin: 0;
    margin-top: 1em;
    background-color: white;
    border-radius: 5px;
  }
  .header-container > .header-title {
    padding: 0;
    margin: 0;
  }
}
